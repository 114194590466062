import React from 'react'

const FormatCurrency = props => {
  let amount = parseFloat(props.children).toFixed(2)

  if (isNaN(amount)) {
    amount = 0
  }

  amount = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return (
    <span>${amount}</span>
  )
}

export default FormatCurrency
