import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import AmoritizationTable from '../components/AmoritizationTable/AmoritizationTable'
import '../components/AmoritizationTable/AmoritizationTable.scss'
import '../components/App/App.scss'
import AppInfo from '../components/AppInfo/AppInfo'
import FormatCurrency from '../components/FormatCurrency/FormatCurrency'
import '../components/Hero/Hero.scss'
import Navbar from '../components/Navbar/Navbar'
import { useLocalStorage } from 'react-use'
const AmortizeJS = require('amortizejs').Calculator

function App () {
  // Inputs
  const [propertyCost, setPropertyCost] = useLocalStorage('propertyCost', '')
  const [downPaymentAmount, setDownPaymentAmount] = useLocalStorage('downPaymentAmount', '')
  const [downPaymentPercent, setDownPaymentPercent] = useLocalStorage('downPaymentPercent', '')
  const [interestRate, setInterestRate] = useLocalStorage('interestRate', 5)
  const [propertyTaxRate, setPropertyTaxRate] = useLocalStorage('propertyTaxRate', 0.79)
  const [vacancyRate, setVacancyRate] = useLocalStorage('vacancyRate', 8)
  const [capexRate, setCapexRate] = useLocalStorage('capexRate', 1)
  const [pmiRate, setPmiRate] = useLocalStorage('pmiRate', 0)
  const [hasPmiBeenSet, setHasPmiBeenSet] = useLocalStorage('hasPmiBeenSet', false)
  const [insurance, setInsurance] = useLocalStorage('insurance', '')
  const [toggleDrawer, setToggleDrawer] = useLocalStorage('toggleDrawer', false)
  const [rents, setRents] = useLocalStorage('rents', '')
  const [customExpenses, setCustomExpenses] = useLocalStorage('customExpenses', [])
  const [term, setTerm] = useLocalStorage('term', 30)
  const [currentTab, setCurrentTab] = useLocalStorage('cashFlow', 'cash-flow')

  const showAdvancedOptions = 'Show Advanced Options'
  const hideAdvancedOptions = 'Hide Advanced Options'

  if (typeof window !== `undefined`) {
    window.localStorage.setItem('lastVisit', new Date())
  }

  // Calculations
  const begMortgageBalance = propertyCost - (propertyCost * (downPaymentPercent / 100))

  const mortgage = AmortizeJS.calculate({
    method: 'mortgage',
    apr: interestRate,
    balance: begMortgageBalance,
    loanTerm: term * 12,
    startDate: new Date()
  })

  // const InfoArea = () => {
  //   if (typeof window === 'undefined') {
  //     return <AppInfo />
  //   }

  //   if (window.innerWidth > 760) {
  //     if (showInfo) {
  //       return <AppInfo />
  //     } else {
  //       return <HeroAnalysis />
  //     }
  //   } else {
  //     if (!showInfo) {
  //       return <HeroAnalysis />
  //     } else {
  //       return null
  //     }
  //   }
  // }

  // Calculated Expenses
  const expenses = [
    {
      name: 'Mortgage Payment',
      cost: mortgage.periodicPayment
    },
    {
      name: 'Property Tax Expense',
      cost: (parseFloat(propertyCost || 0) * (parseFloat(propertyTaxRate || 0) / 100)) / 12
    },
    {
      name: 'Capex Expense',
      cost: parseInt(propertyCost || 0) * (parseInt(capexRate || 0) / 100) / 12
    },
    {
      name: 'PMI Expense',
      cost: (parseInt(propertyCost || 0) * (parseInt(pmiRate || 0) / 100)) / 12
    },
    {
      name: 'Vacancy Expense',
      cost: parseInt(rents || 0) * (parseInt(vacancyRate || 0) / 100)
    },
    {
      name: 'Homeowner\'s Insurance Expense',
      cost: parseInt(insurance || 0) || ((parseInt(propertyCost || 0) / 1000) * 3.5) / 12
    }
  ]

  const allExpenses = [...expenses, ...customExpenses]

  const summedExpenses = () => {
    let sum = 0

    for (let index = 0; index < expenses.length; index++) {
      sum += expenses[index].cost
    }

    for (let index = 0; index < customExpenses.length; index++) {
      sum += parseInt(customExpenses[index].cost)
    }

    return sum
  }

  const totalCashFlow = (rents || 0) - summedExpenses()

  const CashFlowAnalysisTab = props => {
    return (
      <div className='CashFlowAnalysisTab'>
        <h2>Monthly Cash Flow</h2>

        <h3>Income</h3>
        <table className='CashFlowTable'>
          <thead>
            <tr>
              <td>Income Stream</td>
              <td>Amount</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Rental Income</td>
              <td className='IncomeFormat'><FormatCurrency>{rents}</FormatCurrency></td>
            </tr>
            <tr>
              <td>Total Income</td>
              <td className='IncomeFormat'><FormatCurrency>{rents}</FormatCurrency></td>
            </tr>
          </tbody>
        </table>

        <h3>Expenses</h3>
        <table className='CashFlowTable'>
          <thead>
            <tr>
              <td>Expense</td>
              <td>Cost</td>
            </tr>
          </thead>
          <tbody>
            {allExpenses.map((expense, index) => {
              return (
                <tr key={index}>
                  <td>{expense.name}</td>
                  <td className='ExpenseFormat'><FormatCurrency>{expense.cost}</FormatCurrency></td>
                </tr>
              )
            })}

            <tr>
              <td>Total Expenses</td>
              <td className='ExpenseFormat'><FormatCurrency>{summedExpenses()}</FormatCurrency></td>
            </tr>
          </tbody>
        </table>

        <h2>Your monthly cash flow for this property is <span className={totalCashFlow > 0 ? 'IncomeFormat' : 'ExpenseFormat'}><FormatCurrency>{totalCashFlow}</FormatCurrency></span></h2>

      </div>
    )
  }

  const AmoritizationTab = props => {
    return (
      <div className='AmoritizationTableTab'>
        <div className='MortgageStats'>
          <div className='MortgageItem'>
            <div className='MortgageItemLabel'>Monthly Payment</div>
            <div className='MortgageItemValue'><FormatCurrency>{mortgage.periodicPayment}</FormatCurrency></div>
          </div>
          <div className='MortgageItem'>
            <div className='MortgageItemLabel'>Total Interest Paid</div>
            <div className='MortgageItemValue'><FormatCurrency>{mortgage.totalInterest}</FormatCurrency></div>
          </div>
          <div className='MortgageItem'>
            <div className='MortgageItemLabel'>End Date</div>
            <div className='MortgageItemValue'>{`${mortgage.endDate.toLocaleString('en-us', { month: 'short' })} ${mortgage.endDate.getFullYear()}`}</div>
          </div>
          <div className='MortgageItem'>
            <div className='MortgageItemLabel'>Total Payment</div>
            <div className='MortgageItemValue'><FormatCurrency>{mortgage.totalPayment}</FormatCurrency></div>
          </div>
        </div>
        <AmoritizationTable
          mortgage={mortgage} />
      </div>
    )
  }

  const HeroAnalysis = props => {
    return (
      <div className='HeroAnalysis'>
        <button class='ClearButton' onClick={() => {
          window.localStorage.clear()

          document.location.reload()
        }}>Reset Options</button>
        <div className='AnalysisTabs'>
          <span
            onClick={e => setCurrentTab('cash-flow')}
            className={currentTab === 'cash-flow' ? 'AnalysisTab AnalysisTabCurrent' : 'AnalysisTab'}>Cash Flow Analysis</span>
          <span
            onClick={e => setCurrentTab('amoritization')}
            className={currentTab === 'amoritization' ? 'AnalysisTab AnalysisTabCurrent' : 'AnalysisTab'}>Amoritization Table</span>
        </div>
        {currentTab === 'cash-flow' &&
          <CashFlowAnalysisTab />
        }

        {currentTab === 'amoritization' &&
          <AmoritizationTab />
        }

      </div>
    )
  }

  return (
    <div>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Rental Cash Flow Calculator</title>
        <meta name='description' content='Rental Cash Flow App provides a rental cash flow calculator for all of your rental property expenses. Get a detailed look at the cash flow from your property!' />
        <meta name='apple-mobile-web-app-capable' content='yes' />
        <meta name='apple-mobile-web-app-status-bar-style' content='default' />
        <meta name='viewport' content='width=device-width, initial-scale=1, viewport-fit=cover, user-scalable=no' />

        {/* <!-- iPhone Xs Max (1242px x 2688px) -->  */}
        <link rel='apple-touch-startup-image' media='(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3)' href='/apple-launch-1242x2688.png' />

        {/* <!-- iPhone Xr (828px x 1792px) -->  */}
        <link rel='apple-touch-startup-image' media='(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)' href='/apple-launch-828x1792.png' />

        {/* <!-- iPhone X, Xs (1125px x 2436px) -->  */}
        <link rel='apple-touch-startup-image' media='(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)' href='/apple-launch-1125x2436.png' />

        {/* <!-- iPhone 8 Plus, 7 Plus, 6s Plus, 6 Plus (1242px x 2208px) -->  */}
        <link rel='apple-touch-startup-image' media='(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3)' href='/apple-launch-1242x2208.png' />

        {/* <!-- iPhone 8, 7, 6s, 6 (750px x 1334px) -->  */}
        <link rel='apple-touch-startup-image' media='(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)' href='/apple-launch-750x1334.png' />

        {/* <!-- iPad Pro 12.9" (2048px x 2732px) -->  */}
        <link rel='apple-touch-startup-image' media='(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2)' href='/apple-launch-2048x2732.png' />

        {/* <!-- iPad Pro 11” (1668px x 2388px) -->  */}
        <link rel='apple-touch-startup-image' media='(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2)' href='/apple-launch-1668x2388.png' />

        {/* <!-- iPad Pro 10.5" (1668px x 2224px) -->  */}
        <link rel='apple-touch-startup-image' media='(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2)' href='/apple-launch-1668x2224.png' />

        {/* <!-- iPad Mini, Air (1536px x 2048px) -->  */}
        <link rel='apple-touch-startup-image' media='(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2)' href='/apple-launch-1536x2048.png' />

        <link rel='canonical' href='https://rentalcashflow.app' />
      </Helmet>
      <Navbar />
      <section className='Hero'>
        <div className='GetStarted'>
          <form onSubmit={e => {
            e.preventDefault()

            if (typeof window !== `undefined` || window.innerWidth < 760) {
              window.scrollTo(0, document.querySelector('.GetStarted').scrollHeight)
            }
          }}>
            <div className='FieldHolder'>
              <label htmlFor='propertyCost' className='FieldLabel'>Property Cost</label>
              <div className='Field UnitLeft'>
                <span className='Unit'>$</span>
                <input
                  className='FieldInput'
                  id='propertyCost'
                  value={propertyCost}
                  required
                  onFocus={e => e.target.closest('.FieldHolder').classList.add('FieldActive')}
                  onBlur={e => e.target.closest('.FieldHolder').classList.remove('FieldActive')}
                  onChange={e => {
                    setPropertyCost(e.target.value.replace(/\D/, ''))

                    if (downPaymentPercent) {
                      setDownPaymentAmount(e.target.value.replace(/\D/, '') * (downPaymentPercent / 100))
                    }
                  }} />
              </div>
              <div className='HelperDesc'>The amount you will pay for the property. This will be used for determining the down payment percentage, interest paid on the property, property taxes, etc.</div>
            </div>

            <div className='FieldHolder'>
              <label htmlFor='downPaymentAmount' className='FieldLabel'>Down Payment</label>
              <div className='TwoColumnFieldContainer'>
                <div className='Field TwoColumnField UnitLeft'>
                  <span className='Unit'>$</span>
                  <input
                    className='FieldInput'
                    id='downPaymentAmount'
                    value={downPaymentAmount}
                    onFocus={e => e.target.closest('.FieldHolder').classList.add('FieldActive')}
                    onBlur={e => e.target.closest('.FieldHolder').classList.remove('FieldActive')}
                    onChange={(e) => {
                      let percent = (e.target.value / propertyCost) * 100

                      setDownPaymentAmount(e.target.value)
                      setDownPaymentPercent(propertyCost ? percent : 0)

                      if (!hasPmiBeenSet && percent < 20) {
                        setPmiRate(1)
                      } else if (!hasPmiBeenSet && percent > 20) {
                        setPmiRate(0)
                      }
                    }} />
                </div>
                <div className='Field TwoColumnField UnitRight'>
                  <input
                    className='FieldInput'
                    id='downPaymentPercent'
                    value={downPaymentPercent}
                    onFocus={e => e.target.closest('.FieldHolder').classList.add('FieldActive')}
                    onBlur={e => e.target.closest('.FieldHolder').classList.remove('FieldActive')}
                    onChange={e => {
                      setDownPaymentPercent(e.target.value)
                      const newAmount = propertyCost * (e.target.value / 100)
                      setDownPaymentAmount(newAmount.toFixed(2))

                      if (!hasPmiBeenSet && e.target.value < 20) {
                        setPmiRate(1)
                      } else if (!hasPmiBeenSet && e.target.value >= 20) {
                        setPmiRate(0)
                      }
                    }} />
                  <span className='Unit'>%</span>
                </div>
              </div>
              <div className='HelperDesc'>Please supply either a down payment amount or down payment percent for determining the amount of the loan.</div>
            </div>

            <div className='FieldHolder'>
              <label htmlFor='rents' className='FieldLabel'>Rents Collected</label>
              <div className='Field UnitLeft'>
                <span className='Unit'>$</span>
                <input
                  className='FieldInput'
                  id='rents'
                  value={rents}
                  required
                  onFocus={e => e.target.closest('.FieldHolder').classList.add('FieldActive')}
                  onBlur={e => e.target.closest('.FieldHolder').classList.remove('FieldActive')}
                  onChange={e => setRents(e.target.value)} />
              </div>
              <div className='HelperDesc'>Please input all of the income you will be generating from this property. This can include rental income, fees, etc.</div>
            </div>

            <div className='FieldHolder'>
              <label htmlFor='interestRate' className='FieldLabel'>Interest Rate</label>
              <div className='Field UnitRight'>
                <input
                  className='FieldInput'
                  id='interestRate'
                  value={interestRate}
                  required
                  onFocus={e => e.target.closest('.FieldHolder').classList.add('FieldActive')}
                  onBlur={e => e.target.closest('.FieldHolder').classList.remove('FieldActive')}
                  onChange={e => setInterestRate(e.target.value)} />
                <span className='Unit'>%</span>
              </div>
              <div className='HelperDesc'>The interest rate given to you by your mortgage lender.</div>
            </div>

            <div className='FieldHolder'>
              <label htmlFor='propertyTaxRate' className='FieldLabel'>Property Tax Rate</label>
              <div className='Field UnitRight'>
                <input
                  className='FieldInput'
                  id='propertyTaxRate'
                  value={propertyTaxRate}
                  onFocus={e => e.target.parentElement.classList.add('FieldActive')}
                  onBlur={e => e.target.parentElement.classList.remove('FieldActive')}
                  onChange={e => setPropertyTaxRate(e.target.value)} />
                <span className='Unit'>%</span>
              </div>
              <div className='HelperDesc'>Please enter the property tax rate for your specific county. <a href='https://smartasset.com/taxes/property-taxes' target='_blank'>Click here to find an accurate property tax rate for the county of the property.</a></div>
            </div>

            <div
              className={toggleDrawer ? `MoreOptionsDrawer DrawerOpen` : `MoreOptionsDrawer`}>
              <div className='FieldHolder'>
                <label htmlFor='capexRate' className='FieldLabel'>Capex Rate</label>
                <div className='Field UnitRight'>
                  <input
                    className='FieldInput'
                    id='capexRate'
                    value={capexRate}
                    onFocus={e => e.target.closest('.FieldHolder').classList.add('FieldActive')}
                    onBlur={e => e.target.closest('.FieldHolder').classList.remove('FieldActive')}
                    onChange={e => setCapexRate(e.target.value)} />
                  <span className='Unit'>%</span>
                </div>
                <div className='HelperDesc'>The Capex rate is a percentage of the property cost and can be used for generally estimating repairs, maintenance, etc.</div>
              </div>

              <div className='FieldHolder'>
                <label htmlFor='pmiRate' className='FieldLabel'>PMI Rate</label>
                <div className='Field UnitRight'>
                  <input
                    className='FieldInput'
                    id='pmiRate'
                    value={pmiRate}
                    onFocus={e => e.target.closest('.FieldHolder').classList.add('FieldActive')}
                    onBlur={e => e.target.closest('.FieldHolder').classList.remove('FieldActive')}
                    onChange={e => {
                      setHasPmiBeenSet(true)

                      setPmiRate(e.target.value)
                    }} />
                  <span className='Unit'>%</span>
                </div>
                <div className='HelperDesc'>If your loan has less than 20% down payment, or you are using an FHA loan, usually Private Mortgage Insurance (PMI) is required. PMI tends to be 1% of the property cost.</div>
              </div>

              <div className='FieldHolder'>
                <label htmlFor='vacancyRate' className='FieldLabel'>Vacancy Rate</label>
                <div className='Field UnitRight'>
                  <input
                    className='FieldInput'
                    id='vacancyRate'
                    value={vacancyRate}
                    onFocus={e => e.target.closest('.FieldHolder').classList.add('FieldActive')}
                    onBlur={e => e.target.closest('.FieldHolder').classList.remove('FieldActive')}
                    onChange={e => setVacancyRate(e.target.value)} />
                  <span className='Unit'>%</span>
                </div>
                <div className='HelperDesc'>The vacancy rate is a percentage used to determine the amount needed in order to save for any unforeseen vacancies.</div>
              </div>

              <div className='FieldHolder'>
                <label htmlFor='insurance' className='FieldLabel'>Homeowners Insurance Payment (Monthly)</label>
                <div className='Field UnitLeft'>
                  <span className='Unit'>$</span>
                  <input
                    className='FieldInput'
                    id='insurance'
                    value={insurance}
                    onFocus={e => e.target.closest('.FieldHolder').classList.add('FieldActive')}
                    onBlur={e => e.target.closest('.FieldHolder').classList.remove('FieldActive')}
                    onChange={e => setInsurance(e.target.value)} />
                </div>
                <div className='HelperDesc'>Enter the monthly cost needed for this property's Homeowner's Insurance.</div>
              </div>

              {customExpenses.map((el, index) => {
                return (
                  <div className='FieldHolder'>
                    <div className='EditCustomExpenseTitle FieldLabel'>
                      <input
                        onChange={e => {
                          let newArr = [...customExpenses]
                          newArr[index].name = e.target.value

                          setCustomExpenses(newArr)
                        }}
                        autoFocus
                        className='CustomExpenseLabelInput'
                        value={customExpenses[index].name} />
                      <span
                        onClick={e => {
                          let newArr = [...customExpenses]
                          newArr.splice(index)

                          setCustomExpenses(newArr)
                        }}
                        className='DeleteCustomExpense'>
                        <svg viewBox='0 0 14 14'>
                          <path d='M12 3.41L10.59 2 7 5.59 3.41 2 2 3.41 5.59 7 2 10.59 3.41 12 7 8.41 10.59 12 12 10.59 8.41 7z' />
                        </svg>
                      </span>
                    </div>

                    <div className='Field UnitLeft'>
                      <span className='Unit'>$</span>
                      <input
                        className='FieldInput'
                        id='awefawefawef'
                        value={customExpenses[index].cost}
                        onFocus={e => e.target.closest('.FieldHolder').classList.add('FieldActive')}
                        onBlur={e => e.target.closest('.FieldHolder').classList.remove('FieldActive')}
                        onChange={e => {
                          let newArr = [...customExpenses]
                          newArr[index].cost = e.target.value

                          setCustomExpenses(newArr)
                        }} />
                    </div>
                  </div>
                )
              })}

              <div className='FieldHolder'>
                <div className='Field'>
                  <span
                    onClick={e => {
                      const newArr = [...customExpenses]
                      const count = customExpenses.length + 1
                      newArr.push({ name: `Custom Expense ${count}`, cost: 0 })
                      setCustomExpenses(newArr)
                    }}
                    className='AddCustomExpense'>Add Monthly Custom Expense</span>
                </div>
              </div>
            </div>

            <div className='FieldHolder'>
              <div className='Field'>
                <button
                  onClick={e => {
                    e.preventDefault()

                    const inverse = !toggleDrawer
                    setToggleDrawer(inverse)
                  }}
                  className='ToggleMoreOptions'>{toggleDrawer ? hideAdvancedOptions : showAdvancedOptions}</button>
              </div>
            </div>

            <div className='FieldHolder'>
              <div className='Field'>
                { typeof window !== 'undefined' && window.innerWidth < 760 &&
                  <button
                    onClick={() => {
                      if (typeof window !== `undefined` || window.innerWidth < 760) {
                        window.scrollTo(0, 0)
                      }
                    }}
                    type='submit'
                    className='Button FullButton'>Calculate</button>
                }
              </div>
            </div>
          </form>
        </div>
        {propertyCost && rents && interestRate &&
          <HeroAnalysis />
        }

        {(!propertyCost || !rents || !interestRate) &&
          <AppInfo />
        }

      </section>
    </div>
  )
}

export default App
