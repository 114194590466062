import React from 'react'
import heroImage from './hero-image.svg'

const AppInfo = props => {
  if (typeof window !== 'undefined' && window.innerWidth < 760) {
    return null
  }

  return (
    <div className='HeroInfo'>
      <p style={{ backgroundColor: `#fffed9`, color: `#868336`, padding: `.5rem 1rem`, borderRadius: `5px` }}>Start filling out fields to get your calculation.</p>
      <img alt='' src={heroImage} />
      <h1>Get a Cash Flow Analysis for your Rental in just 5 steps</h1>
      <p>Rental Cash Flow App makes it easy to input rental income and expenses to determine cash flow for a property. Use the app for an existing property or to determine if your next rental investment is a good buy!</p>
    </div>
  )
}

export default AppInfo
