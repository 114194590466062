import React from 'react'
import './Navbar.scss'

const Navbar = props => {
  return (
    <div className='Navbar'>
      <a className='Brand' href='/'>Rental Cash Flow</a>
      {/* <nav className='PrimaryNav'>
        <ul>
          <li><a href='#'>About</a></li>
          <li><a href='#'>Buying</a></li>
          <li><a href='#'>Selling</a></li>
        </ul>
      </nav> */}
    </div>
  )
}

export default Navbar
