import React from 'react'
import FormatCurrency from '../FormatCurrency/FormatCurrency'

const AmoritizationTable = props => {
  let totalInterest = 0

  return (
    <table className='AmoritizationTable'>
      <thead>
        <tr>
          <td>Period</td>
          <td>Principal</td>
          <td>Interest</td>
          <td>Total Interest</td>
          <td>Balance</td>
        </tr>
      </thead>
      <tbody>
        {props.mortgage.schedule.map((period, index) => {
          totalInterest += period.interest
          return (
            <tr key={index}>
              <td>{`${period.date.toLocaleString('en-us', { month: 'short' })} ${period.date.getFullYear()}`}</td>
              <td><FormatCurrency>{period.principal}</FormatCurrency></td>
              <td><FormatCurrency>{period.interest}</FormatCurrency></td>
              <td><FormatCurrency>{totalInterest}</FormatCurrency></td>
              <td><FormatCurrency>{period.remainingBalance}</FormatCurrency></td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default AmoritizationTable
